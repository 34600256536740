import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Create_Services,
  Services_list,Services_Changes,
  Services_withoutpage,Create_Slot,
  Create_ExistSlot,Slot_list,delete_ExistSlot,
  delete_ExistSlotmain,Create_package,
  Packages_list,edit_slots,
  exists_Package,
  assign_Package,
  package_details,
  slots_details,
  Addon_Exist_package,
  Delete_ExistItem_package,
  agent_details,
  admin_kyctoagent,
  Admin_details,
  AgentDropDownList,
  admin_fundtoagent,
  admin_fundAdd,
  AdminLagerCreditReport,
  AdminLagerDebitReport,
  edit_Package,
  PackageStatusONOFF,
  AdminUPIReport,
  AdminPayoutReport,
  AdminRechargeReport,
  AdminExpressReport,
  AdminUPIList,
  AdminBenefeciaryList,
  AdminAEPSReport,
  UPIstatusONOFF,
  BenifecirystatusONOFF,
  AdminLagerCreditWallet,
  AdminLagerDebitWallet,
  AdminDMTReport
} from './Api';



export const Add_Services = createAsyncThunk('common/Add_Services', async (filterdata, thunkAPI) => {
    try {
       const response = await Create_Services(filterdata);
       // Extract relevant data from the response
       const responseData = {
           data: response.data,
           status: response.status,
           // You can extract more data if needed
       };
       return responseData;
    } catch (error) {
      throw error.response.data;
    }
});


export const Add_Slot = createAsyncThunk('common/Add_Slot', async (filterdata, thunkAPI) => {
  try {
     const response = await Create_Slot(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});

export const ExistAdd_Slot = createAsyncThunk('common/ExistAdd_Slot', async (filterdata, thunkAPI) => {
  try {
     const response = await Create_ExistSlot(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});

export const List_slot = createAsyncThunk('common/List_slot', async (filterdata,thunkAPI) => {
  try {
     const response = await Slot_list(filterdata);
     return {
         data: response.data,
         status: response.status,
         headers: {
             'content-length': response.headers['content-length'],
             'content-type': response.headers['content-type']
             // Add other necessary header fields
         }
     };
  } catch (error) {
    throw error.response.data;
  }
});

export const List_Services = createAsyncThunk('common/List_Services', async (filterdata,thunkAPI) => {
    try {
       const response = await Services_list(filterdata);
       return {
           data: response.data,
           status: response.status,
           headers: {
               'content-length': response.headers['content-length'],
               'content-type': response.headers['content-type']
               // Add other necessary header fields
           }
       };
    } catch (error) {
      throw error.response.data;
    }
});


export const changeStatus_Services = createAsyncThunk('common/changeStatus_Services', async (filterdata, thunkAPI) => {
    try {
       const response = await Services_Changes(filterdata);
       // Extract relevant data from the response
       const responseData = {
           data: response.data,
           status: response.status,
           // You can extract more data if needed
       };
       return responseData;
    } catch (error) {
      throw error.response.data;
    }
});

export const without_Services = createAsyncThunk('common/without_Services', async (thunkAPI) => {
    try {
       const response = await Services_withoutpage();
       // Extract relevant data from the response
       const responseData = {
           data: response.data,
           status: response.status,
           // You can extract more data if needed
       };
       return responseData;
    } catch (error) {
      throw error.response.data;
    }
});


export const Existdelete_Slot = createAsyncThunk('common/Existdelete_Slot', async (filterdata, thunkAPI) => {
  try {
     const response = await delete_ExistSlot(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});

export const Existdelete_Slotmain = createAsyncThunk('common/Existdelete_Slotmain', async (filterdata, thunkAPI) => {
  try {
     const response = await delete_ExistSlotmain(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});

export const Add_package = createAsyncThunk('common/Add_package', async (filterdata, thunkAPI) => {
  try {
     const response = await Create_package(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});

export const Exist_package_Addon = createAsyncThunk('common/Exist_package_Addon', async (filterdata, thunkAPI) => {
  try {
     const response = await Addon_Exist_package(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});
export const Existdelete_ItemPackage = createAsyncThunk('common/Existdelete_ItemPackage', async (filterdata, thunkAPI) => {
  try {
     const response = await Delete_ExistItem_package(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});

export const Package_list = createAsyncThunk('common/Package_list', async (filterdata,thunkAPI) => {
  try {
     const response = await Packages_list(filterdata);
     return {
         data: response.data,
         status: response.status,
         headers: {
             'content-length': response.headers['content-length'],
             'content-type': response.headers['content-type']
             // Add other necessary header fields
         }
     };
  } catch (error) {
    throw error.response.data;
  }
});



export const slot_edits = createAsyncThunk('common/slot_edits', async (filterdata, thunkAPI) => {
  try {
     const response = await edit_slots(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});


export const package_edits = createAsyncThunk('common/package_edits', async (filterdata, thunkAPI) => {
  try {
     const response = await edit_Package(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});


export const exist_package = createAsyncThunk('common/exist_package', async (filterdata, thunkAPI) => {
  try {
     const response = await exists_Package(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});


// Assign Package=========

export const Assign_package = createAsyncThunk('common/Assign_package', async (filterdata, thunkAPI) => {
  try {
     const response = await assign_Package(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});


// Package details
export const Package_Details = createAsyncThunk('common/Package_Details', async (filterdata, thunkAPI) => {
  try {
     const response = await package_details(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});

// Slots details
export const Slots_Details = createAsyncThunk('common/Slots_Details', async (filterdata, thunkAPI) => {
  try {
     const response = await slots_details(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});

//Agent details

export const Agent_Profile = createAsyncThunk('common/Agent_Profile', async (filterdata, thunkAPI) => {
  try {
     const response = await agent_details(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});

//KYC approve/reject

export const Agent_KYCByAdmin = createAsyncThunk('common/Agent_KYCByAdmin', async (filterdata, thunkAPI) => {
  try {
     const response = await admin_kyctoagent(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});


export const DropdownList = createAsyncThunk('common/DropdownList', async (thunkAPI) => {
  try {
     const response = await AgentDropDownList();
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});


export const Details_Admin = createAsyncThunk('common/Details_Admin', async (thunkAPI) => {
  try {
     const response = await Admin_details();
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});

export const FundToAgent = createAsyncThunk('common/FundToAgent', async (filterdata, thunkAPI) => {
  try {
     const response = await admin_fundtoagent(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});


export const AddFundAdmin = createAsyncThunk('common/AddFundAdmin', async (filterdata, thunkAPI) => {
  try {
     const response = await admin_fundAdd(filterdata);
     // Extract relevant data from the response
     const responseData = {
         data: response.data,
         status: response.status,
         // You can extract more data if needed
     };
     return responseData;
  } catch (error) {
    throw error.response.data;
  }
});

export const AdminCreditReports= createAsyncThunk('common/AdminCreditReports', async (filter) => {
  const response = await AdminLagerCreditReport(filter);
 // console.log(response)
  return response.data;
});

export const AdminCreditWalletReports= createAsyncThunk('common/AdminCreditWalletReports', async (filter) => {
  const response = await AdminLagerCreditWallet(filter);
 // console.log(response)
  return response.data;
});

export const AdminDebitWalletReports= createAsyncThunk('common/AdminDebitWalletReports', async (filter) => {
  const response = await AdminLagerDebitWallet(filter);
 // console.log(response)
  return response.data;
});



export const AdminDebitReports= createAsyncThunk('common/AdminDebitReports', async (filter) => {
  const response = await AdminLagerDebitReport(filter);
 // console.log(response)
  return response.data;
});

export const AdminReportsUPI= createAsyncThunk('common/AdminReportsUPI', async (filter) => {
  const response = await AdminUPIReport(filter);
 // console.log(response)
  return response.data;
}); 

export const AdminReportsPayout= createAsyncThunk('common/AdminReportsPayout', async (filter) => {
  const response = await AdminPayoutReport(filter);
 // console.log(response)
  return response.data;
}); 

export const AdminRechargeReports= createAsyncThunk('common/AdminRechargeReports', async (filter) => {
  const response = await AdminRechargeReport(filter);
 // console.log(response)
  return response.data;
});

export const AdminReportsExpress= createAsyncThunk('common/AdminReportsExpress', async (filter) => {
  const response = await AdminExpressReport(filter);
 // console.log(response)
  return response.data;
});

export const AdminReportsDMT= createAsyncThunk('common/AdminReportsDMT', async (filter) => {
  const response = await AdminDMTReport(filter);
 // console.log(response)
  return response.data;
});



export const ChangePAckageStatus= createAsyncThunk('common/ChangePAckageStatus', async (filter) => {
  const response = await PackageStatusONOFF(filter);
 // console.log(response)
  return response.data;
});

export const UPIALL= createAsyncThunk('common/UPIALL', async (filter) => {
  const response = await AdminUPIList(filter);
 // console.log(response)
  return response.data;
});

export const BeneficiaryALL= createAsyncThunk('common/BeneficiaryALL', async (filter) => {
  const response = await AdminBenefeciaryList(filter);
 // console.log(response)
  return response.data;
});

export const AEPSReports= createAsyncThunk('common/AEPSReports', async (filter) => {
  const response = await AdminAEPSReport(filter);
 // console.log(response)
  return response.data;
});

export const UPIChangeStatus= createAsyncThunk('common/UPIChangeStatus', async (filter) => {
  const response = await UPIstatusONOFF(filter);
 // console.log(response)
  return response.data;
});
export const BenChangeStatus= createAsyncThunk('common/BenChangeStatus', async (filter) => {
  const response = await BenifecirystatusONOFF(filter);
 // console.log(response)
  return response.data;
});



  export const resetSuccess = () => {
    return {
      type: 'common/resetSuccess',
    };
  };


export const commonSlice = createSlice({
    name: 'common',
    initialState: {
      isLoading: false,
      isSuccess:false,
      error: null,
      message: '',
      details:null,
      agentdetails:null,
      slotdetails:null,
      admindetails:null,
      agentList:[],
      itemsList: [],
      legerReport:[],
      legerReportWallet:[],
      DebitlegerReportWallet:[],
      itemCount: 0,
      perPage: 10,
      page: 1,
      pageCount: 0,
    },
    reducers: {
       
  },
    extraReducers: (builder) => {
      builder
      .addCase(Add_Services.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(Add_Services.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.isLoading = false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isLoading = true;
          state.isSuccess=false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
      })
      .addCase(Add_Services.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })

      .addCase(Agent_KYCByAdmin.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(Agent_KYCByAdmin.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isSuccess=false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
        state.isLoading = false;
      })
      .addCase(Agent_KYCByAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })
      
      .addCase(Add_Slot.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(Add_Slot.fulfilled, (state, action) => {
        console.log(action.payload.data.success);
        if (action.payload.data.success==true) {
          
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
         
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
        state.isLoading = false;
      })
      .addCase(Add_Slot.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })
      .addCase(ExistAdd_Slot.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(ExistAdd_Slot.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.isLoading = false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isLoading = true;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
      })
      .addCase(ExistAdd_Slot.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })
      
      .addCase(List_Services.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(List_Services.fulfilled, (state, action) => {
          console.log(action.payload.data);
        if (action.payload.data.success) {
          state.isSuccess = action.payload.data.success;
          state.itemsList = action.payload.data.message.list.itemsList;
          state.itemCount = action.payload.data.message.list.itemCount;        ;
          state.perPage = action.payload.data.message.list.perPage;
          state.page = action.payload.data.message.list.currentPage;
          state.pageCount = action.payload.data.message.list.pageCount;

        } else {         

          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.itemsList = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        }
        state.isLoading = false;
      })
      .addCase(List_Services.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;

      })
      .addCase(changeStatus_Services.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(changeStatus_Services.fulfilled, (state, action) => {
          console.log(action.payload.data,"changes")
        if (action.payload.data.success) {
          state.isLoading = false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isLoading = true;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
      })
      .addCase(changeStatus_Services.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })
      .addCase(without_Services.pending, (state) => {
        console.log("Pending")
        state.isLoading = true;
      })
      .addCase(without_Services.fulfilled, (state, action) => {
        console.log("fullfill")
       
        if (action.payload.data.success==true) {
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.itemsList = action.payload.data.message.list;

        } else {       
          state.itemsList = [];
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
        state.isLoading = false;
      })
      .addCase(without_Services.rejected, (state, action) => {
        console.log("reject")
        state.isLoading = true;
        state.isSuccess = false;

      })
      .addCase(List_slot.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(List_slot.fulfilled, (state, action) => {
          console.log(action.payload.data);
        if (action.payload.data.success) {
          state.isSuccess = action.payload.data.success;
          state.itemsList = action.payload.data.message.list.itemsList;
          state.itemCount = action.payload.data.message.list.itemCount;        ;
          state.perPage = action.payload.data.message.list.perPage;
          state.page = action.payload.data.message.list.currentPage;
          state.pageCount = action.payload.data.message.list.pageCount;

        } else {         
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;;
          state.itemsList = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        }
        state.isLoading = false;
      })
      .addCase(List_slot.rejected, (state, action) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(Existdelete_Slot.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(Existdelete_Slot.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.isLoading = false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isLoading = true;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
      })
      .addCase(Existdelete_Slot.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })
      .addCase(Existdelete_Slotmain.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(Existdelete_Slotmain.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.isLoading = false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isLoading = true;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
      })
      .addCase(Existdelete_Slotmain.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })
      .addCase(Add_package.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(Add_package.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.isLoading = false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isLoading = true;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
      })
      .addCase(Add_package.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })
      .addCase(Package_list.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Package_list.fulfilled, (state, action) => {
          console.log(action.payload.data);
        if (action.payload.data.success) {
          state.isSuccess = action.payload.data.success;
          state.itemsList = action.payload.data.message.list.itemsList;
          state.itemCount = action.payload.data.message.list.itemCount;        ;
          state.perPage = action.payload.data.message.list.perPage;
          state.page = action.payload.data.message.list.currentPage;
          state.pageCount = action.payload.data.message.list.pageCount;

        } else {         

          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.itemsList = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        }
        state.isLoading = false;
      })
      .addCase(Package_list.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;

      })
      .addCase(slot_edits.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(slot_edits.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.isLoading = false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isLoading = true;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
      })
      .addCase(slot_edits.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })

      .addCase(package_edits.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(package_edits.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.isLoading = false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isLoading = true;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
      })
      .addCase(package_edits.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })

      .addCase(exist_package.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(exist_package.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.isLoading = false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isLoading = true;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
      })
      .addCase(exist_package.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })
      .addCase(Assign_package.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(Assign_package.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.isLoading = false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isLoading = true;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
      })
      .addCase(Assign_package.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })
      .addCase(Package_Details.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Package_Details.fulfilled, (state, action) => {
          console.log(action.payload.data.message.details);
        if (action.payload.data.success==true) {
          state.isSuccess = action.payload.data.success;
          state.details = action.payload.data.message.details[0];
        } else {         
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.details=null
        }
        state.isLoading = false;
      })
      .addCase(Package_Details.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;

      })
      .addCase(Slots_Details.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Slots_Details.fulfilled, (state, action) => {
          console.log(action.payload.data.message.details,"");
        if (action.payload.data.success==true) {
          state.isSuccess = action.payload.data.success;
          state.slotdetails = action.payload.data.message.details[0];
        } else {         
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.slotdetails=null
        }
        state.isLoading = false;
      })
      .addCase(Slots_Details.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;

      })
      .addCase(Agent_Profile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Agent_Profile.fulfilled, (state, action) => {
          console.log(action.payload.data.message.details);
        if (action.payload.data.success==true) {
          state.isSuccess = action.payload.data.success;
          state.agentdetails = action.payload.data.message.details;
        } else {         
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.agentdetails=null
        }
        state.isLoading = false;
      })
      .addCase(Agent_Profile.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;

      })
      
      .addCase(Exist_package_Addon.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(Exist_package_Addon.fulfilled, (state, action) => {
        if (action.payload.data.success==true) {
          state.isLoading = false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isLoading = true;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
      })
      .addCase(Exist_package_Addon.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })
      .addCase(Existdelete_ItemPackage.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(Existdelete_ItemPackage.fulfilled, (state, action) => {
        if (action.payload.data.success==true) {
          state.isLoading = false;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.error = false;
        } else {         
          state.isLoading = true;
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
        }
      })
      .addCase(Existdelete_ItemPackage.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = false;
        state.error = true;
      })
      
      .addCase(Details_Admin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Details_Admin.fulfilled, (state, action) => {
          console.log(action.payload.data.message);
        if (action.payload.data.success==true) {
          state.isSuccess = action.payload.data.success;
          state.admindetails = action.payload.data.message.message;
          console.log(action.payload.data.message.message,"=======>")
        } else {         
          state.message = action.payload.data.message.message;
          state.isSuccess = action.payload.data.success;
          state.admindetails=null
        }
        state.isLoading = false;
      })
      .addCase(Details_Admin.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;

      })

      .addCase(DropdownList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(DropdownList.fulfilled, (state, action) => {
          console.log(action.payload.data.message);
        if (action.payload.data.success==true) {
          state.isSuccess = action.payload.data.success;
          state.agentList = action.payload.data.message.agents;
          console.log(action.payload.data.message.agents,"=======>")
        } else {         
          state.message = action.payload.data.message;
          state.isSuccess = action.payload.data.success;
          state.agentList=null
        }
        state.isLoading = false;
      })
      .addCase(DropdownList.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;

      })

      .addCase(FundToAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(FundToAgent.fulfilled, (state, action) => {
          console.log(action.payload.data.message);
        if (action.payload.data.success==true) {
          state.isSuccess = action.payload.data.success;
          //state.agentList = action.payload.data.message.agents;
          console.log(action.payload.data.message.agents,"=======>")
        } else {         
          state.message = action.payload.data.message;
          state.isSuccess = action.payload.data.success;
         // state.agentList=null
        }
        state.isLoading = false;
      })
      .addCase(FundToAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;

      })

      .addCase(AddFundAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AddFundAdmin.fulfilled, (state, action) => {
          console.log(action.payload.data.message);
        if (action.payload.data.success==true) {
          state.isSuccess = action.payload.data.success;
          //state.agentList = action.payload.data.message.agents;
          console.log(action.payload.data.message.agents,"=======>")
        } else {         
          state.message = action.payload.data.message;
          state.isSuccess = action.payload.data.success;
         // state.agentList=null
        }
        state.isLoading = false;
      })
      .addCase(AddFundAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;

      })

      .addCase(AdminCreditReports.pending, (state) => {
        state.isLoading = true;
        console.log("action.payload.message.agents5555555");
      })
      .addCase(AdminCreditReports.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Reports=============");
        if (action.payload.success==true) {
          state.legerReport = action.payload.message.transationList.itemsList;
          state.itemCount = action.payload.message.transationList.itemCount;
          state.perPage = action.payload.message.transationList.perPage;
          state.page = action.payload.message.transationList.currentPage;
          state.pageCount = action.payload.message.transationList.pageCount;
       
        } else {
          // Reset state if no data returned or unsuccessful
          state.legerReport = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        
        }
        state.isLoading = false;
      })
      
      .addCase(AdminCreditReports.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(AdminCreditWalletReports.pending, (state) => {
        state.isLoading = true;
       
      })
      .addCase(AdminCreditWalletReports.fulfilled, (state, action) => {
      
        if (action.payload.success==true) {
          state.legerReportWallet = action.payload.message.transationList.itemsList;
          state.itemCount = action.payload.message.transationList.itemCount;
          state.perPage = action.payload.message.transationList.perPage;
          state.page = action.payload.message.transationList.currentPage;
          state.pageCount = action.payload.message.transationList.pageCount;
       
        } else {
          // Reset state if no data returned or unsuccessful
          state.legerReportWallet = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        
        }
        state.isLoading = false;
      })
      
      .addCase(AdminCreditWalletReports.rejected, (state, action) => {
       
        state.isLoading = false;
        state.error = action.payload;
      })
      

      .addCase(AdminDebitWalletReports.pending, (state) => {
        state.isLoading = true;
       
      })
      .addCase(AdminDebitWalletReports.fulfilled, (state, action) => {
        console.log(action.payload.message,"Debit Ladger")
        if (action.payload.success==true) {
          state.DebitlegerReportWallet = action.payload.message.transationList.itemsList;
          state.itemCount = action.payload.message.transationList.itemCount;
          state.perPage = action.payload.message.transationList.perPage;
          state.page = action.payload.message.transationList.currentPage;
          state.pageCount = action.payload.message.transationList.pageCount;
       
        } else {
          // Reset state if no data returned or unsuccessful
          state.DebitlegerReportWallet = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        
        }
        state.isLoading = false;
      })
      
      .addCase(AdminDebitWalletReports.rejected, (state, action) => {
       
        state.isLoading = false;
        state.error = action.payload;
      })
      
      


      .addCase(AdminDebitReports.pending, (state) => {
        state.isLoading = true;
        console.log("action.payload.message.agents5555555");
      })
      .addCase(AdminDebitReports.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Reports=============");
        if (action.payload.success==true) {
          state.legerReport = action.payload.message.transationList.itemsList;
          state.itemCount = action.payload.message.transationList.itemCount;
          state.perPage = action.payload.message.transationList.perPage;
          state.page = action.payload.message.transationList.currentPage;
          state.pageCount = action.payload.message.transationList.pageCount;
       
        } else {
          // Reset state if no data returned or unsuccessful
          state.legerReport = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        
        }
        state.isLoading = false;
      })
      
      .addCase(AdminDebitReports.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(AdminReportsUPI.pending, (state) => {
        state.isLoading = true;
        console.log("action.payload.message.agents5555555");
      })
      .addCase(AdminReportsUPI.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Reports UPiiiii=============");
        if (action.payload.success==true) {
          state.legerReport = action.payload.message.transationList.itemsList;
          state.itemCount = action.payload.message.transationList.itemCount;
          state.perPage = action.payload.message.transationList.perPage;
          state.page = action.payload.message.transationList.currentPage;
          state.pageCount = action.payload.message.transationList.pageCount;
       
        } else {
          // Reset state if no data returned or unsuccessful
          state.legerReport = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        
        }
        state.isLoading = false;
      })
      
      .addCase(AdminReportsUPI.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })
     
      .addCase(AdminReportsPayout.pending, (state) => {
        state.isLoading = true;
        console.log("action.payload.message.agents5555555");
      })
      .addCase(AdminReportsPayout.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Reports UPiiiii=============");
        if (action.payload.success==true) {
          state.legerReport = action.payload.message.transationList.itemsList;
          state.itemCount = action.payload.message.transationList.itemCount;
          state.perPage = action.payload.message.transationList.perPage;
          state.page = action.payload.message.transationList.currentPage;
          state.pageCount = action.payload.message.transationList.pageCount;
       
        } else {
          // Reset state if no data returned or unsuccessful
          state.legerReport = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        
        }
        state.isLoading = false;
      })
      
      .addCase(AdminReportsPayout.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(AdminRechargeReports.pending, (state) => {
        state.isLoading = true;
        console.log("action.payload.message.agents5555555");
      })
      .addCase(AdminRechargeReports.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Reports UPiiiii=============");
        if (action.payload.success==true) {
          state.legerReport = action.payload.message.transationList.itemsList;
          state.itemCount = action.payload.message.transationList.itemCount;
          state.perPage = action.payload.message.transationList.perPage;
          state.page = action.payload.message.transationList.currentPage;
          state.pageCount = action.payload.message.transationList.pageCount;
       
        } else {
          // Reset state if no data returned or unsuccessful
          state.legerReport = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        
        }
        state.isLoading = false;
      })
      
      .addCase(AdminRechargeReports.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(AdminReportsExpress.pending, (state) => {
        state.isLoading = true;
        console.log("action.payload.message.agents5555555");
      })
      .addCase(AdminReportsExpress.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Reports UPiiiii=============");
        if (action.payload.success==true) {
          state.legerReport = action.payload.message.transationList.itemsList;
          state.itemCount = action.payload.message.transationList.itemCount;
          state.perPage = action.payload.message.transationList.perPage;
          state.page = action.payload.message.transationList.currentPage;
          state.pageCount = action.payload.message.transationList.pageCount;
       
        } else {
          // Reset state if no data returned or unsuccessful
          state.legerReport = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        
        }
        state.isLoading = false;
      })
      
      .addCase(AdminReportsExpress.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(AdminReportsDMT.pending, (state) => {
        state.isLoading = true;
        console.log("action.payload.message.agents5555555");
      })
      .addCase(AdminReportsDMT.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Reports UPiiiii=============");
        if (action.payload.success==true) {
          state.legerReport = action.payload.message.transationList.itemsList;
          state.itemCount = action.payload.message.transationList.itemCount;
          state.perPage = action.payload.message.transationList.perPage;
          state.page = action.payload.message.transationList.currentPage;
          state.pageCount = action.payload.message.transationList.pageCount;
       
        } else {
          // Reset state if no data returned or unsuccessful
          state.legerReport = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        
        }
        state.isLoading = false;
      })
      
      .addCase(AdminReportsDMT.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })
      
      
      .addCase(ChangePAckageStatus.pending, (state) => {
        state.isLoading = true;
       
      })
      .addCase(ChangePAckageStatus.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Pacakge Status Reports=============");
        if (action.payload.success==true) {
          state.message = action.payload.data.message;
        } else {
          state.message = action.payload.data.message;
        
        }
        state.isLoading = false;
      })
      
      .addCase(ChangePAckageStatus.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(UPIChangeStatus.pending, (state) => {
        state.isLoading = true;
       
      })
      .addCase(UPIChangeStatus.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Pacakge Status Reports=============");
        if (action.payload.success==true) {
          state.message = action.payload.data.message;
        } else {
          state.message = action.payload.data.message;
        
        }
        state.isLoading = false;
      })
      
      .addCase(UPIChangeStatus.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(BenChangeStatus.pending, (state) => {
        state.isLoading = true;
       
      })
      .addCase(BenChangeStatus.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Pacakge Status Reports=============");
        if (action.payload.success==true) {
          state.message = action.payload.data.message;
        } else {
          state.message = action.payload.data.message;
        
        }
        state.isLoading = false;
      })
      
      .addCase(BenChangeStatus.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })


      .addCase(UPIALL.pending, (state) => {
        state.isLoading = true;
        console.log("action.payload.message.agents5555555");
      })
      .addCase(UPIALL.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Reports UPI ALL=============");
        if (action.payload.success==true) {
          state.legerReport = action.payload.message.UPIUser.itemsList;
          state.itemCount = action.payload.message.UPIUser.itemCount;
          state.perPage = action.payload.message.UPIUser.perPage;
          state.page = action.payload.message.UPIUser.currentPage;
          state.pageCount = action.payload.message.UPIUser.pageCount;
       
        } else {
          // Reset state if no data returned or unsuccessful
          state.legerReport = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        
        }
        state.isLoading = false;
      })
      
      .addCase(UPIALL.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(BeneficiaryALL.pending, (state) => {
        state.isLoading = true;
        console.log("action.payload.message.agents5555555");
      })
      .addCase(BeneficiaryALL.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Reports UPI ALL=============");
        if (action.payload.success==true) {
          state.legerReport = action.payload.message.beneficiary.itemsList;
          state.itemCount = action.payload.message.beneficiary.itemCount;
          state.perPage = action.payload.message.beneficiary.perPage;
          state.page = action.payload.message.beneficiary.currentPage;
          state.pageCount = action.payload.message.beneficiary.pageCount;
       
        } else {
          // Reset state if no data returned or unsuccessful
          state.legerReport = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        
        }
        state.isLoading = false;
      })
      
      .addCase(BeneficiaryALL.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(AEPSReports.pending, (state) => {
        state.isLoading = true;
        console.log("action.payload.message.agents5555555");
      })
      .addCase(AEPSReports.fulfilled, (state, action) => {
        console.log(action.payload.message,"========Reports UPI ALL=============");
        if (action.payload.success==true) {
          state.legerReport = action.payload.message.transationList.itemsList;
          state.itemCount = action.payload.message.transationList.itemCount;
          state.perPage = action.payload.message.transationList.perPage;
          state.page = action.payload.message.transationList.currentPage;
          state.pageCount = action.payload.message.transationList.pageCount;
       
        } else {
          // Reset state if no data returned or unsuccessful
          state.legerReport = [];
          state.itemCount = 0;
          state.perPage = 10;
          state.page = 1;
          state.pageCount = 0;
        
        }
        state.isLoading = false;
      })
      
      .addCase(AEPSReports.rejected, (state, action) => {
        console.log("action.payload.message.agents");
        state.isLoading = false;
        state.error = action.payload;
      })
      
    },
});

export default commonSlice.reducer;
