import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Agent_LoginOTPService,
   LoginVerifyAgentService
  ,GoDashboard
  ,LogoutandOTPService,
  ForgotPassword,VerifyForgotPassword,
   LogoutVerifyService,NewChangePassword } from './Api2';

export const loginAgent = createAsyncThunk('agent/login', async (credentials, thunkAPI) => {
    try {
       const response = await Agent_LoginOTPService(credentials);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  });


  export const logoutandcontinueUser = createAsyncThunk('agent/logoutandcontinue', async (credentials, thunkAPI) => {
    try {
       const response = await LogoutandOTPService(credentials);
       console.log(response);
       return response;
    } catch (error) {
      throw error.response.data;
    }
});

  export const logoutUserVerify = createAsyncThunk('agent/logoutverify', async (credentials, thunkAPI) => {
    try {
       const response = await LogoutVerifyService(credentials);
     //  console.log(response);
       return response;
    } catch (error) {
      throw error.response.data;
    }
});


export const ForgotSendOTP = createAsyncThunk('agent/ForgotSendOTP', async (credentials, thunkAPI) => {
  try {
     const response = await ForgotPassword(credentials);
     console.log(response);
     return response;
  } catch (error) {
    throw error.response.data;
  }
});

export const changePasswordForgot = createAsyncThunk('agent/changePasswordForgot', async (credentials, thunkAPI) => {
  try {
     const response = await VerifyForgotPassword(credentials);
   //  console.log(response);
     return response;
  } catch (error) {
    throw error.response.data;
  }
});

export const loginVerifyAgent = createAsyncThunk('agent/loginVerifyAgent', async (credentials, thunkAPI) => {
    try {
       const response = await LoginVerifyAgentService(credentials);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  });

  export const GoToDashboard = createAsyncThunk('agent/GoToDashboard', async (credentials, thunkAPI) => {
    try {
       const response = await GoDashboard();
      return response;
    } catch (error) {
      throw error.response.data;
    }
  });


  export const AgentChnageNewPassword= createAsyncThunk('agent/AgentChnageNewPassword', async (filter) => {
    const response = await NewChangePassword(filter);
   // console.log(response)
    return response.data;
  });
  

  export const resetLogoutSuccess = () => {
    return {
      type: 'agent/resetLogoutSuccess',
    };
  };


export const agentSlice = createSlice({
    name: 'agent',
    initialState: {
      user:  null,
      isLoading: false,
      logoutsuccess: false,
      newsuccess: false,
      error: null,
      message: '',
      agent_isKyc:localStorage.getItem("agent_isKyc") ||null,
      agent_isLogin: localStorage.getItem("agent_isLogin") || false,
      agent_isToken: localStorage.getItem("agent_isToken") || null,
      agent_isRole:  localStorage.getItem("agent_isRole") || null,
    },
    reducers: {
      resetLogoutSuccess: (state) => {
        state.isLoading = false;
      }
  },
    extraReducers: (builder) => {
      builder
        .addCase(loginAgent.pending, (state) => {
          state.isLoading = true;
          state.success = false;
          state.message = ''; 
        })
        .addCase(loginAgent.fulfilled, (state, action) => {
          if (action.payload.data.success) {
         
            state.message = action.payload.data.message;
            state.success = action.payload.data.success;
            state.error = false;
          } else {         
           
            state.message = action.payload.data.message;
            state.success = action.payload.data.success;
            state.error = true;
          }
          state.isLoading = false;
        })
        .addCase(loginAgent.rejected, (state, action) => {
          state.isLoading = false;
          state.error = false;
          state.message = action.error.message;
          state.success = false;
          state.error = true;
        })
        .addCase(loginVerifyAgent.pending, (state) => {
          state.isLoading = true;
          state.success = false;
          state.message = ''; 
        })
        .addCase(loginVerifyAgent.fulfilled, (state, action) => {
        
          if (action.payload.data.success==true) {
            state.isLoading = false;
            state.user = action.payload.data.message.message;
            state.message = action.payload.data.message.message;
            state.success = action.payload.data.success;
            state.error = false;
            state.agent_isLogin= action.payload.data.success;
            state.agent_isToken= "agentanshPay "+action.payload.data.message.message.refresh_Token;
            state.agent_isRole= action.payload.data.message.message.role;
            state.agent_isKyc=action.payload.data.message.message.kyc_status;
            localStorage.setItem("agent_mobile",action.payload.data.message.message.mobile)
            localStorage.setItem("agent_logindate",action.payload.data.message.message.loginDate)
            localStorage.setItem("agent_isKyc",action.payload.data.message.message.kyc_status)
            localStorage.setItem("agent_isLogin",action.payload.data.success)
            localStorage.setItem("agent_isToken","agentanshPay "+action.payload.data.message.message.refresh_Token)
            localStorage.setItem("agent_isRole",action.payload.data.message.message.role)
            localStorage.setItem("agent_isType",action.payload.data.message.message.userType)
            


          } else {         
            state.isLoading = true;
            state.message = action.payload.data.message;
            state.success = action.payload.data.success;
            state.error = true;
            localStorage.setItem("agent_mobile",null)
            localStorage.setItem("agent_isKyc",null)
            localStorage.setItem("agent_logindate",null)
            localStorage.setItem("agent_isLogin",false)
            localStorage.setItem("agent_isToken",null)
            localStorage.setItem("agent_isRole",null)
          }
        })
        .addCase(loginVerifyAgent.rejected, (state, action) => {
          state.isLoading = false;
          state.error = false;
          state.message = action.error.message;
          state.success = false;
          state.error = true;
        })
        .addCase(logoutandcontinueUser.pending, (state) => {
          state.isLoading = true;
          state.newsuccess = false;
          state.message = '';
        })
        .addCase(logoutandcontinueUser.fulfilled, (state, action) => {
          if (action.payload.data.success) {
            state.isLoading = false;
            state.message = action.payload.data.message;
            state.newsuccess = action.payload.data.success;
          } else {
            state.isLoading = true;
            state.message = action.payload.data.message;
            state.newsuccess = action.payload.data.success;
          }
        })
        .addCase(logoutandcontinueUser.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        })
        .addCase(logoutUserVerify.pending, (state) => {
          state.isLoading = true;
          state.logoutsuccess = false;
          state.message = '';
        })
        .addCase(logoutUserVerify.fulfilled, (state, action) => {
          if (action.payload.data.success) {
            state.isLoading = false;
            state.message = action.payload.data.message;
            state.logoutsuccess = action.payload.data.success;
            localStorage.removeItem("agent_isKyc");
            localStorage.removeItem("agent_isLogin");
            localStorage.removeItem("agent_isToken");
            localStorage.removeItem("agent_isRole");

            state.user = null;
            state.agent_isKyc = null;
            state.agent_isLogin = false;
            state.agent_isToken = null;
            state.agent_isRole = null;
            state.logoutsuccess = true;
            

          } else {
            state.isLoading = true;
            state.message = action.payload.data.message;
            state.logoutsuccess = action.payload.data.success;
          }
        })
        .addCase(logoutUserVerify.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        })
        .addCase(GoToDashboard.pending, (state) => {
          state.isLoading = true;
          state.success = false;
          state.message = ''; 
        })
        .addCase(GoToDashboard.fulfilled, (state, action) => {
   
          if (action.payload.data.success==true) {
            state.isLoading = false;
            state.user = action.payload.data.message.message;
            state.message = action.payload.data.message.message;
            state.success = action.payload.data.success;
            state.error = false;
            state.agent_isLogin= action.payload.data.success;
            state.agent_isToken= "agentanshPay "+action.payload.data.message.message.refresh_Token;
            state.agent_isRole= action.payload.data.message.message.role;
            state.agent_isKyc=action.payload.data.message.message.kyc_status;
            localStorage.setItem("agent_mobile",action.payload.data.message.message.mobile)
            localStorage.setItem("agent_isKyc",action.payload.data.message.message.kyc_status)
            localStorage.setItem("agent_isLogin",action.payload.data.success)
            localStorage.setItem("agent_isToken","agentanshPay "+action.payload.data.message.message.refresh_Token)
            localStorage.setItem("agent_isRole",action.payload.data.message.message.role)

          } else {         
            state.isLoading = true;
            state.message = action.payload.data.message;
            state.success = action.payload.data.success;
            state.error = true;
            localStorage.setItem("agent_mobile",null)
            localStorage.setItem("agent_isKyc",null)
            localStorage.setItem("agent_isLogin",false)
            localStorage.setItem("agent_isToken",null)
            localStorage.setItem("agent_isRole",null)
          }
        })
        .addCase(GoToDashboard.rejected, (state, action) => {
          state.isLoading = false;
          state.error = false;
          state.message = action.error.message;
          state.success = false;
          state.error = true;
        })
        .addCase(ForgotSendOTP.pending, (state) => {
          state.isLoading = true;
          state.newsuccess = false;
          state.message = '';
        })
        .addCase(ForgotSendOTP.fulfilled, (state, action) => {
          if (action.payload.data.success) {
            state.isLoading = false;
            state.message = action.payload.data.message;
            state.newsuccess = action.payload.data.success;
          } else {
            state.isLoading = true;
            state.message = action.payload.data.message;
            state.newsuccess = action.payload.data.success;
          }
        })
        .addCase(ForgotSendOTP.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        })
        .addCase(changePasswordForgot.pending, (state) => {
          state.isLoading = true;
          state.logoutsuccess = false;
          state.message = '';
        })
        .addCase(changePasswordForgot.fulfilled, (state, action) => {
          if (action.payload.data.success) {
            state.isLoading = false;
            state.message = action.payload.data.message;
            state.logoutsuccess = action.payload.data.success;
          } else {
            state.isLoading = true;
            state.message = action.payload.data.message;
            state.logoutsuccess = action.payload.data.success;
          }
        })
        .addCase(changePasswordForgot.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        })
        .addCase(AgentChnageNewPassword.pending, (state) => {
          state.isLoading = true;
          state.success = false;

        })
        .addCase(AgentChnageNewPassword.fulfilled, (state, action) => {
          console.log(action.payload,"pppppppppppppppppppp")
          if (action.payload.success==true) {
          
            state.message = action.payload.message.message;
            state.success = true;
          } else {
           
            state.message = action.payload.message.message;
            state.success = false;
          }
          state.isLoading = false;
        })
        .addCase(AgentChnageNewPassword.rejected, (state, action) => {
          state.isLoading = false;
          state.success =false;
        })
        
    },
});

export default agentSlice.reducer;
