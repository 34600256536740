
import { ADMIN_SERVICE_URL,DEFAULT_TOKEN } from './Config';
import axios from 'axios';

import store from '../store';



const getTokenFromStore = () => {
  const { auth } = store.getState();
  return auth.isToken; // Assuming 'isToken' is the token state in your 'auth' slice
};


// Create an axios instance with a response interceptor to handle token expiration
const axiosInstance = axios.create({
  baseURL: ADMIN_SERVICE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-apay': DEFAULT_TOKEN,
  },
});
axiosInstance.interceptors.response.use(
  (response) => {
    console.log(response);
    if (response.data.message === 'This token is already expired!!' || 
        response.data.message === 'Forbidden error! You must set token type' || 
        response.data.message === 'Forbidden error! You must set token' || 
        response.data.message === 'Forbidden error!' || 
        response.data.message === 'Invalid token' || 
        response.data.message === 'Unauthorized Token!') {
      // Handle token expiration or forbidden error here
      // For example, clear localStorage or redirect to login page
      // localStorage.clear();
      // window.location.href = '/admin/login';
    }
    return response;
  },
  (error) => {
    // Handle network errors or other API-related errors here
    // You may want to throw a custom error or return a custom error object
    // depending on how you want to handle these cases
    return Promise.reject(error);
  }
);


// axiosInstance.interceptors.response.use(
//   (response) => {
//      console.log(response)
//     // return;
//     if (response.data.message == 'This token is already expired!!' || response.data.message == 'Forbidden error! You must set token type' || response.data.message =='Forbidden error! You must set token' || response.data.message =='Forbidden error!'|| response.data.message =='Invalid token' || response.data.message =='Unauthorized Token!') {
     
//       // window.location.href = '/admin/login';
//     }
//     return response;
//   },
//   (error) => {
//     // Handle network errors or other API-related errors here
//     // You may want to throw a custom error or return a custom error object
//     // depending on how you want to handle these cases
//     return Promise.reject(error);
//   }
// );

const LoginOTPService = async (requestData) => {
  try {
    const response = await axiosInstance.post(`/signin`, requestData);
    const authToken = response.headers['x-apay'];
    return {authToken, data: response.data };
  } catch (error) {
    return error;
  }
}
const LoginVerifyService = async (requestData) => {
  try {
    const response = await axiosInstance.post(`/VerifyOtp`, requestData);
    const authToken = response.headers['x-apay'];
    return {authToken, data: response.data };
  } catch (error) {
    return error;
  }
}
const LogoutandOTPService = async (requestData) => {
  try {
    const response = await axiosInstance.post(`/logoutandcontinue`, requestData);
    const authToken = response.headers['x-apay'];
    return {authToken, data: response.data };
  } catch (error) {
    return error;
  }
}

const LogoutVerifyService = async (requestData) => {
  try {
    const response = await axiosInstance.post(`/LogoutVerifyOtp`, requestData);
    const authToken = response.headers['x-apay'];
    return {authToken, data: response.data };
  } catch (error) {
    return error;
  }
}


const adharSendOTP = async (requestData) => {

    const response = await axiosInstance.post(`/aadharSendOtp`, requestData, {
      headers: {
          'x-apay':getTokenFromStore(),// localStorage.getItem("admin_isToken"), // Replace with your custom header
        }
     
  });
    return response;
}

const adharVerifyOTP = async (requestData) => {

  const response = await axiosInstance.post(`/aadharVerifyOtp`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
   
});
  return response;
}


const verifyPAN = async (requestData) => {
  const response = await axiosInstance.post(`/panVerify`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}

const postalCode = async (requestData) => {
  const response = await axiosInstance.post(`/postalcode`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}

const mobilesendotp = async (requestData) => {
  const response = await axiosInstance.post(`/mobileOtp`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}
const mobileverifyotp = async (requestData) => {
  const response = await axiosInstance.post(`/mobileverify`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}


const agent_register = async (requestData) => {
  const response = await axiosInstance.post(`/user_registred`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}
const update_onboard_member = async (requestData) => {
  const response = await axiosInstance.post(`/update_onboard`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}


const email_checker = async (requestData) => {
  const response = await axiosInstance.post(`/emailCheck`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}


const Agent_List = async (requestData) => {
  console.log("aaja")
  const response = await axiosInstance.post(`/agent_list`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}

const Create_Services = async (requestData) => {
  const response = await axiosInstance.post(`/add_service`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}

const Services_list = async (requestData) => {
  const response = await axiosInstance.post(`/services_list`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}

const Services_Changes = async (requestData) => {
  const response = await axiosInstance.post(`/services_Change`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}


const Services_withoutpage = async () => {

let response = await axiosInstance.get(`/services_listWithoutpage`,{
  headers: {
    'x-apay': getTokenFromStore() // Replace with your custom header
  },
})
  return response;
}


const Create_Slot = async (requestData) => {
  const response = await axiosInstance.post(`/add_slot`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
      }
});
  return response;
}

const Create_ExistSlot = async (requestData) => {
  const response = await axiosInstance.post(`/existadd_slot`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}

const Slot_list = async (requestData) => {
  const response = await axiosInstance.post(`/slot_list`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}

const delete_ExistSlot = async (requestData) => {
  const response = await axiosInstance.post(`/existdelete_slot`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}

const delete_ExistSlotmain = async (requestData) => {
  const response = await axiosInstance.post(`/existdelete_slotmain`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}


const Create_package = async (requestData) => {
  const response = await axiosInstance.post(`/add_package`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}


const Packages_list = async (requestData) => {
  const response = await axiosInstance.post(`/packages_list`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}

const edit_slots = async (requestData) => {
  const response = await axiosInstance.post(`/edit_slot`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}

const edit_Package = async (requestData) => {
  const response = await axiosInstance.post(`/edit_package`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}


const exists_Package= async (requestData) => {
  const response = await axiosInstance.post(`/exists_package`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}

const assign_Package= async (requestData) => {
  const response = await axiosInstance.post(`/package_assign`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}
const agents_package_list= async (requestData) => {
  const response = await axiosInstance.post(`/agent_list_withpackage`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}

const package_details= async (requestData) => {
  const response = await axiosInstance.post(`/packages_details`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}
const slots_details= async (requestData) => {
  const response = await axiosInstance.post(`/slots_details`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}


const Addon_Exist_package = async (requestData) => {
  const response = await axiosInstance.post(`/exist_package_addon`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}
const Delete_ExistItem_package = async (requestData) => {
  const response = await axiosInstance.post(`/existItemdelete_package`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}



const agent_details= async (requestData) => {
  const response = await axiosInstance.post(`/agent_profile`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}

const admin_kyctoagent= async (requestData) => {
  const response = await axiosInstance.post(`/agent_kyc`, requestData, {
    headers: {
        'x-apay': getTokenFromStore(),
      }
});
  return response;
}

const Admin_details = async () => {

  let response = await axiosInstance.get(`/admin_details`,{
    headers: {
      'x-apay': getTokenFromStore() // Replace with your custom header
    },
  })
    return response;
  }

  const AgentDropDownList = async () => {

    let response = await axiosInstance.get(`/agentlist_dropdown`,{
      headers: {
        'x-apay': getTokenFromStore() // Replace with your custom header
      },
    })
      return response;
    }

    const AgentDropDownListWithName = async () => {

      let response = await axiosInstance.get(`/agent_list_withname`,{
        headers: {
          'x-apay': getTokenFromStore() // Replace with your custom header
        },
      })
        return response;
      }

      const RoleBaseAgent= async (requestData) => {
        const response = await axiosInstance.post(`/agent_list_withRoleType`, requestData, {
          headers: {
              'x-apay': getTokenFromStore(),
            }
      });
        return response;
      }

      const AgentTransfer= async (requestData) => {
        const response = await axiosInstance.post(`/agent_transfer`, requestData, {
          headers: {
              'x-apay': getTokenFromStore(),
            }
      });
        return response;
      }

      const AgentHoldAmount= async (requestData) => {
        const response = await axiosInstance.post(`/agent_holdAmount`, requestData, {
          headers: {
              'x-apay': getTokenFromStore(),
            }
      });
        return response;
      }

      const AgentUnHoldAmount= async (requestData) => {
        const response = await axiosInstance.post(`/agent_unholdAmount`, requestData, {
          headers: {
              'x-apay': getTokenFromStore(),
            }
      });
        return response;
      }

      
      
    
    const admin_fundtoagent= async (requestData) => {
      const response = await axiosInstance.post(`/fundtransferto_agent`, requestData, {
        headers: {
            'x-apay': getTokenFromStore(),
          }
    });
      return response;
    }
    
    
    const admin_fundAdd= async (requestData) => {
      const response = await axiosInstance.post(`/add_fund`, requestData, {
        headers: {
            'x-apay': getTokenFromStore(),
          }
    });
      return response;
    }
    const AdminLagerCreditReport = async (requestData) => {
      const response = await axiosInstance.post(`/credit_report`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }

    const AdminLagerCreditWallet= async (requestData) => {
      const response = await axiosInstance.post(`/credit_reportforAdmin`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }

    const AdminLagerDebitWallet= async (requestData) => {
      const response = await axiosInstance.post(`/debit_leadgerreport`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }

    
    
    const AdminLagerDebitReport = async (requestData) => {
      const response = await axiosInstance.post(`/debit_report`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }

    const AdminUPIReport = async (requestData) => {
      const response = await axiosInstance.post(`/upi_report`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }

    const AdminPayoutReport = async (requestData) => {
      const response = await axiosInstance.post(`/payout_report`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }

    const AdminRechargeReport = async (requestData) => {
      const response = await axiosInstance.post(`/recharge_report`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }
    const AdminExpressReport = async (requestData) => {
      const response = await axiosInstance.post(`/express_report`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }
    const AdminDMTReport = async (requestData) => {
      const response = await axiosInstance.post(`/dmt_report`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }
    
    const AdminSendSMS = async (requestData) => {
      const response = await axiosInstance.post(`/agent_SendSms`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }
    
    const PackageStatusONOFF = async (requestData) => {
      const response = await axiosInstance.post(`/package_on_off`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }
    const UPIstatusONOFF = async (requestData) => {
      const response = await axiosInstance.post(`/upi_on_off`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }
    const AgentCreditReport = async (requestData) => {
      const response = await axiosInstance.post(`/agentreports_credit`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }


    const AgentDebitReport = async (requestData) => {
      const response = await axiosInstance.post(`/agentreports_debit`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }
    

    const BenifecirystatusONOFF = async (requestData) => {
      const response = await axiosInstance.post(`/beneficiary_on_off`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }
    

    const Admin_Logout = async () => {
      const response = await axiosInstance.get(`/Adminlogout`,  {
        headers: {
          'x-apay':getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }

    const AdminUPIList = async (requestData) => {
      const response = await axiosInstance.post(`/adminupi_list`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }

    const AdminBenefeciaryList = async (requestData) => {
      const response = await axiosInstance.post(`/adminbeneficiary_list`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }

    const AdminAEPSReport = async (requestData) => {
      const response = await axiosInstance.post(`/adminaepstransation_list`, requestData, {
        headers: {
          'x-apay': getTokenFromStore(),//localStorage.getItem("admin_isToken"), // Replace with your custom header
          }
    });
      return response;
    }

  
export {
  LoginOTPService,
  LoginVerifyService,
  LogoutandOTPService,
  LogoutVerifyService,
  adharSendOTP,
  verifyPAN,
  adharVerifyOTP,
  postalCode,
  mobilesendotp,
  mobileverifyotp,
  agent_register,
  email_checker,
  Agent_List,
  Create_Services,
  Services_list,
  Services_Changes,
  Services_withoutpage,
  Create_Slot,
  Create_ExistSlot,
  Slot_list,
  delete_ExistSlot,
  delete_ExistSlotmain,
  Create_package,
  Packages_list,
  edit_slots,
  exists_Package,
  assign_Package,
  agents_package_list,
  package_details,
  slots_details,
  Addon_Exist_package,
  Delete_ExistItem_package,
  agent_details,
  admin_kyctoagent,
  Admin_details,
  AgentDropDownList,
  admin_fundtoagent,
  admin_fundAdd,
  AdminLagerCreditReport,
  update_onboard_member,
  AgentDropDownListWithName,
  RoleBaseAgent,
  AgentTransfer,
  AgentHoldAmount,
  AdminSendSMS,
  edit_Package,
  AgentUnHoldAmount,
  PackageStatusONOFF,
  AdminLagerDebitReport,Admin_Logout,
  AdminUPIReport,
  AdminPayoutReport,
  AdminRechargeReport,
  AdminExpressReport,
  AdminUPIList,
  AdminBenefeciaryList,
  AdminAEPSReport,
  UPIstatusONOFF,
  BenifecirystatusONOFF,
  AgentCreditReport,
  AgentDebitReport,
  AdminLagerCreditWallet,
  AdminLagerDebitWallet,
  AdminDMTReport
}
