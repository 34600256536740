// LocationContext.js
import React, { createContext, useState, useEffect } from 'react';

const LocationContext = createContext();

const LocationProvider = ({ children }) => {
  const [isLocationEnabled, setLocationEnabled] = useState(false);

  const enableLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // User has allowed access to their location
          setLocationEnabled(true);
          // You can now do something with the user's location
          console.log(`Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`);
        },
        (error) => {
          // User has denied access to their location or an error occurred
          setLocationEnabled(false);
          console.error('Error getting location:', error.message);
        },
        {
          enableHighAccuracy: true, // Request the most accurate location data available
          timeout: 10000, // Set a timeout (in milliseconds) for the location request
          maximumAge: 0 // Do not use a cached position
        }
      );
    }
  };

  useEffect(() => {
    // Check if location is already enabled when the component mounts
    enableLocation();
  }, []);

  return (
    <LocationContext.Provider value={{ isLocationEnabled, enableLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

export { LocationContext, LocationProvider };
